<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
        <div class="grabble">
          <div style="width: 96%;display: flex;align-items: center;">
            <el-input
              class="hunt"
              v-model="name"
              placeholder="请输入会员姓名"
              size="large"
              @input="orangutan"
			  clearable 
            />
            <el-input
              class="hunt"
              v-model="topic"
              placeholder="请输入会员编码"
              size="large"
              @input="orangutan"
			  clearable 
            />
            <!-- 会员组织名称搜索 -->
              <el-select
                class="hunt"
                v-model="ability"
                placeholder="请选择组织名称"
                size="large"
                @click="multiple"
                clearable
                @change="numerical"
				@focus="closeIt"
				ref="selectIt"
              />
            <!-- 时间选择 -->
              <el-date-picker
                  class="hunt"
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="large"
                  @change="immediUpdate"
                />
        </div>
        <el-button style="margin-left: 10px" type="primary" size="large" @click="search1" :loading='formative'
              ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
            >
        <!-- <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div> -->
      </div>
           <div class="eave">
              <div class="statistics">
                统计：{{altogether}}条数据
              </div>
              <div>
                 <el-button style="margin-left: 10px" type="primary" size="default" @click="operation" :loading='whirl'>手动过期</el-button>
                 <el-dropdown style="margin-left: 12px;" split-button type="primary" @command="handleCommand" :hide-on-click="around">
                  <div class="loading" v-show="wrought"></div>

                    导出
                    <template #dropdown>
                      <el-dropdown-menu style="width: 117px;">
                        <el-dropdown-item command="personage">过期积分合计</el-dropdown-item>
                        <el-dropdown-item command="personage1">过期积分明细</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
           </div>
      <!-- <div class="summation">
        <div class="hint">统计 : {{altogether}}篇</div>
        <div class="hint">提示 : 动态只有发布人所管理工会会员可见，其他不可见。</div>
      </div> -->
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :empty-text="texts"
        :header-cell-style="{ background: '#F5F7FA' }"
      >
        <!-- 会员姓名 -->
        <el-table-column
         fixed 
          label="会员姓名"
          prop="name"
          align="center"
          :show-overflow-tooltip='true'
          width="120" 
        >
        </el-table-column>
        <!-- 会员编码  -->
        <el-table-column
          label="会员编码"
          prop="username"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
          width="120" 
        >
        </el-table-column>
        <!-- 所属工会  -->
        <el-table-column
          label="所属工会 "
          prop="unionName"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
          width="150" 
        >
        </el-table-column>
        <!-- 过期时间  -->
        <el-table-column
          label="过期时间 "
          prop="createTime"
          align="center"
          :formatter="reach"
          :show-overflow-tooltip='true'
          width="150" 
        >
        </el-table-column>
        <!-- 过期类型 -->
        <el-table-column
          label="过期类型"
          prop="type"
          align="center"
          :formatter="formatSex"
          :show-overflow-tooltip='true'
          width="120" 
        >
        </el-table-column>
        <!-- 积分值 -->
        <el-table-column
          label="积分值"
          prop="integralValue"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 操作人 -->
        <el-table-column
          label="操作人"
          prop="adminName"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 备注  -->
        <el-table-column
          label="备注 "
          prop="reason"
          align="center"
          :formatter="describe"
          :show-overflow-tooltip='true'
          width="150" 
        >
        </el-table-column>
        <!-- <el-table-column label="操作" align="center" width="265">
          <template #default="scope">
            <el-button
              size="default"
              @click.stop="copyreader(scope.row.username)"
              class="bulur"
              ><el-icon class="cancel"> <Search /> </el-icon>积分明细</el-button
            >
            <el-button size="default" @click.stop="expurgate(scope.row.username)" class="red"
              ><el-icon class="cancel"> <Search /> </el-icon>现金券明细</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :page-sizes="[6, 12, 18, 24]"
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        :total="altogether"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <!-- <div class="floating" @click="ocean">
        <img class="images" src="../../assets/xiazai.png" alt="">
      </div> -->
    </el-card>
     <!-- 所属组织弹窗 -->
    <div>
      <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
    <!-- 下载弹窗 -->
    <div>
      <s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
        <div class="task">
          {{quoth}}
        </div>
      </s3-layer>
    </div>
    <!-- 预约下载中心 -->
    <!-- <el-dialog v-model="centre" width="50%">
      <template #header>
        <div class="minitype">预约下载中心</div>
        <div class="trousers">
          <img class="tishi" src="../../assets/tishi.png" alt="">
          <div>文件生成后仅当天有效，过期自动删除</div>
        </div>
      </template> 
      
        <div class="ceshi">
          <el-table :data="table" style="width: 100%" :header-cell-style="{ background: '#F5F7FA' }"  border :empty-text="subscribe">
          <el-table-column prop="createTime" label="申请时间" width="150" align="center" :formatter="own" :show-overflow-tooltip='true' />
          <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip='true' />
          <el-table-column prop="fileName" label="文件名"  align="center" :show-overflow-tooltip='true'/>
          <el-table-column prop="state" label="状态"  align="center" :formatter="condition" :show-overflow-tooltip='true'/>
          <el-table-column label="操作" align="center" width="110" prop="state">
            <template #default="scope">
              <el-button
                v-show="scope.row.state==1"
                size="default"
                @click="copyreader(scope.row.link)"
                class="bulur"
                ><el-icon class="cancel"> <Download /> </el-icon>下载</el-button
              >
              <div v-show="scope.row.state==2">-</div>
            </template>
          </el-table-column>
        </el-table>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="centre = false">
              关闭
            </el-button>
          </span>
        </template>
      </el-dialog> -->
  </div>
</template>
<script>
// import { useRouter  } from "vue-router";
import { ref,onMounted,onDeactivated,onActivated } from 'vue';
import { GQlist,Zzzlist,GQtotal,XZall,GQdetail,ZClist} from '../../utils/api'
import { Search,Download } from "@element-plus/icons-vue";
import qs from 'qs'
import { useRouter,useRoute  } from "vue-router";
import moments from 'moment'
import { ElMessage } from "element-plus";
export default {
  name:"JFexceed",
  setup() {
    const route = useRoute();
    // 发布状态
    // let condition=(row, colum,cellValue)=>{
    //    if (cellValue == 1) {
    //     return "已完成";
    //   } else if (cellValue === 2) {
    //     return "生成中";
    //   }
    // }
    // 预约中心接口
    // const all =()=>{
    //   XZall().then((res)=>{
    //     console.log(res)
    //     if(res.data.code==0){
    //       table.value=res.data.data.fileExportList
    //       if(table.value.length==0){
    //         subscribe.value='暂无数据'
    //       }
    //     }else{
    //       ElMessage({
    //         message: res.data.msg,
    //         type: "error",
    //         center: true,
    //       });
    //     }
    //   })
    // }
    // 右侧浮窗点击
    // const ocean=()=>{
    //   centre.value=true
    //   all()
    // }
    // let own=(row, colum,cellValue )=>{
    //  if(typeof cellValue=='number'){
    //     return moments(cellValue*1000).format("YYYY-MM-DD HH:mm")
    //   }
    // }







    // 下载提示弹窗
    let quoth=ref('')
    // const table = ref([])
    // 预约下载中心表格提示
    // const subscribe=ref('加载中')
    // 预约下载中心弹窗
    // const centre=ref(false)
    // 手动过期按钮跳转
    const operation=()=>{
      router.push('/JFmanual')
    }
    // 下载点击弹窗
    const anew=ref(false)
    // 下拉按钮动态收回
    const around=ref(false)
    // 下拉点击加载
    const wrought=ref(false)
    // 过期积分明细接口下载
    let expired=()=>{
      let data={
        name:name.value,
        username:topic.value,
        unionCode:Hcode.value,
        startTime:a.value,
        endTime:out.value,
      }
      GQdetail(qs.stringify(data)).then((res)=>{
        if(res.data.code==0){
          anew.value=true
          quoth.value='任务已提交，请在右侧查看进度'
        }else{
          anew.value=false
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
        }
      })
    }
    // 过期积分合计接口下载
    let overdue =()=>{
      let data={
        name:name.value,
        username:topic.value,
        unionCode:Hcode.value,
        startTime:a.value,
        endTime:out.value,
      }
      console.log(data)
      GQtotal(qs.stringify(data)).then((res)=>{
        if(res.data.code==0){
          anew.value=true
          quoth.value='任务已提交，请在右侧查看进度'
        }else{
          anew.value=false
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
        }
      })
    }
    const handleCommand=(command)=>{
      console.log(command)
      if(command=='personage'){
        overdue()
      }else if (command=='personage1'){
        expired()
      }
    }


    // 搜索加载
    const formative=ref(false)
    // 判断描述
    let describe= (row, column, cellValue) => {
      // console.log(cellValue)
      if (cellValue == null||cellValue == '') {
        return "-";
      } else if (cellValue !== null||cellValue !== '') {
        return cellValue;
      }
    };
    // 判断编码
    let encoding= (row, column, cellValue) => {
      // console.log(cellValue)
      if (cellValue == null||cellValue == '') {
        return "-";
      } else if (cellValue !== null||cellValue !== '') {
        return cellValue;
      }
    };
    // 判断类型
    let formatSex= (row, column, cellValue) => {
      if (cellValue == 1) {
        return "手动过期";
      } else if (cellValue === 2) {
        return "自动过期";
      }
    };
    // let asd=ref('')
    let reach=(row, colum,cellValue )=>{
      
      // console.log(moments(cellValue*1000).format("YYYY-MM-DD HH:mm"))
      // asd.value=moments(cellValue*1000).format().substring(0,16)
      if(typeof cellValue=='number'){
        return moments(cellValue*1000).format("YYYY-MM-DD HH:mm")
      }
    }
    let a =ref('')
    let b =ref('')
    let c=ref('')
    let out=ref('')

    // 时间清空监听
    const immediUpdate=(e)=>{
      if(e!==null){
        a.value=new Date(e[0]).getTime()/1000
        b.value=new Date(e[1]).getTime()/1000
        c.value=moments(b.value*1000).format().substring(0,10)
        out.value= new Date( c.value+' '+ '23:59:59').getTime()/1000
      }else if(e==null){
        a.value=''
        b.value=''
        c.value=''
        out.value=''
      }
      console.log(e)
    }
    // 时间选择
    let value1=ref('')
    // 现金券明细
    const expurgate=(res)=>{
      sessionStorage.setItem('YEticket', res)
      router.push('/YEticket')
      console.log(res)
    }




    // 路由跳转
    const router = useRouter()
    // 积分明细
    const copyreader=(res)=>{
      window.open(res)
    }
    // 会员编码
    const topic=ref('')
    // 会员姓名
    const name = ref('')
    const zong =ref(null)
    // 工会清空
    const numerical=(e)=>{
      if(e==''){
        Hcode.value=''
      }
    }

    
        // 获取code 
    let Hcode=ref('')
    // 搜索组织选择值
    let ability=ref('')
    // 弹窗树形选择
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    const lang=()=>{
      ZClist().then((res)=>{
        console.log(res)
        texture.value=res.data.data.manageUnionList
      })
    }
    // 弹窗组织列表树形
    let texture =ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
     // 选择组织按钮
    let multiple =()=>{
      visible.value=true
    }
    // 搜索组织弹窗
    let visible=ref(false)


let selectIt = ref()
	// 下拉隐藏
	let closeIt = () => {
		selectIt.value.blur();
	}








    // 列表内容显示
    let texts= ref('加载中')
    // 搜索框值变化清空
    let orangutan=()=>{
      // if(topic.value==''&&values.value==''){
      //   list()
      // }
    }
    let search1=()=>{
      formative.value=true
      search()
    }
    // 搜索
    let search=()=>{
      
      console.log('会员姓名',name.value)
      console.log('会员编码',topic.value)
      console.log('工会code',Hcode.value)
      console.log('开始时间',a.value)
      console.log('结束时间',b.value)
      
      console.log('jieshu',out.value)
      let data ={
        limit:branches.value,
        page:currentPage.value,
        name:name.value,
        username:topic.value,
        unionCode:Hcode.value,
        startTime:a.value,
        endTime:out.value,
      }
      GQlist(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          formative.value=false
          altogether.value=res.data.data.page.totalCount
          filterTableData.value=res.data.data.page.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 分页
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(ability.value!==''||name.value!==''||topic.value!==''||a.value!==''||b.value!==''){
        search()
      }else{
        list()
      }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      if(ability.value!==''||name.value!==''||topic.value!==''||a.value!==''||b.value!==''){
        search()
      }else{
        list()
      }
    }
    // 总条数
    let altogether=ref(null)
    // 工会动态列表
    let branches=ref(6)
    const list =()=>{
      let data ={
        // 六条数据
        limit:branches.value,
        // 1页
        page:currentPage.value,
        name:'',
        username:'',
        unionCode:'',
        startTime:'',
        endTime:'',
      }
      GQlist(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          altogether.value=res.data.data.page.totalCount
          filterTableData.value=res.data.data.page.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // onDeactivated(()=>{
    //   list()
    // })
    // let texts=ref('加载中')
    let translate=ref(2)
    onActivated(()=>{
      translate.value=route.params.userId
      if(translate.value==1){
        name.value=''
        topic.value=''
        ability.value=''
        Hcode.value=''
        value1.value=''
        a.value=''
        b.value=''
        c.value=''
        out.value=''
        list()
        lang()
      }
      console.log('以后只执行')
    })
    onMounted(()=>{
      // console.log('执行一次')
      list()
      lang()
    })
    // 头部搜索
    let values=ref('')
    // 路由跳转
    // const router = useRouter()
    // 列表数据循环
    const filterTableData = ref([])
    return {
      // condition,
      // own,
      quoth,
      // table,
      // subscribe,
      // ocean,
      // centre,
      anew,
      operation,
      around,
      handleCommand,
      wrought,
      // 搜索加载
      search1,
      formative,



      describe,
      encoding,
      formatSex,
      reach,
      immediUpdate,
      value1,
      // 现金券明细
      expurgate,
      // 积分明细
      copyreader,
      topic,
      name,
      zong,
      numerical,
      ability,
      texture,
      defaultProps,
      dendrogram,
      visible,
      multiple,
      texts,
      orangutan,
      // 搜索
      search,
      // 分页
      handleSizeChange,
      handleCurrentChange,
      altogether,
      pagesize,
      currentPage,
      values,
      // 循环列表数据
      filterTableData,
	  closeIt,
	  selectIt,
    };
  },
  components: {
    Download,
    Search
  },
};
</script>
<style lang="scss" scoped>
.tishi{
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.trousers{
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 15px;
}
.minitype{
  font-size: 16px;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
.images{
  width: 20px;
  height: 20px;
}
.floating{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0%;
  top: 50%;
  width: 40px;
  height: 40px;
  background-color:#409EFF;
  border-radius: 5px 0 0 5px;
  z-index: 10;
}
.loading {
  width: 8px;
  height: 8px;
  border: 2px solid #C9E3FF;
  border-top-color: transparent;
  border-radius: 100%;
  animation: circle infinite 0.75s linear;
  margin-right: 5px;
}
.eave{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.statistics{
  font-size: 14px;
  color: #909399;
  // margin-bottom: 15px;
  // margin-left: 10px;
}
::v-deep.el-table__body-wrapper{
    background-color: #409eff;
  }
  ::v-deep.el-table__body-wrapper::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }
// ::v-deep .el-table {
//   &__fixed {
//     pointer-events: none !important;
//     td {
//       pointer-events: none !important;
//     }
//   }
// }
::v-deep .el-table{
  overflow: auto !important;
}
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:block !important;
}
.chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
.summation{
  display: flex;
  justify-content: space-between;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
.el-select--large{
  width: 20%;
  // margin-left: 10px;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 22%;
  margin-right: 10px;
  margin-left: 10px;
}
.grabble {
  width: 86%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.grabble3 {
  width: 86%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
// .el-scrollbar__thumb {
//   background-color: #ffffff;
// }
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  // overflow-x: auto;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
.ceshi{
  height: 215px !important;
  overflow: auto ;
  margin-top: -20px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
}
 .ceshi::-webkit-scrollbar{
  display: none !important;
}
</style>
<style>
</style>
